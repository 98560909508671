import { Fragment, useState } from "react"
import { Button } from "react-bootstrap"
import { useWebSocket } from "./App"

const SubscribeToRoomComponent = () => {

    const socket = useWebSocket()

    const [serverMsg, setServerMsg] = useState("")
    const [subscribed, setSubscribed] = useState(false)
    const toggle_subscription = () => {
        setSubscribed(!subscribed) 
        if(!subscribed) {
            console.log("Join room")
            socket.emit('join_room', { room: 'demo-room' });
            socket.on("demo-room-message", function(data) { 
                console.log("demo-room-message event from server. Message: " + data.msg)
                setServerMsg(data.msg) 
            } )
        } else {
            console.log("Leave room")
            socket.emit('leave_room', { room: 'demo-room' });
            socket.off("demo-room")
        }
    }

    return (<Fragment>
        <h1>{serverMsg}</h1>
        <Button variant="primary" onClick={ toggle_subscription }>{subscribed ? "Subscribed" : "Not subscribed"}</Button>
    </Fragment>)
}

export default SubscribeToRoomComponent